<template>
  <div class="page" :class="isWX?'':'noBgPage'">
    <div class="sharePage" v-if="isWX">
      <div class="shareArrow">
        <img src="@/assets/icons/wx-share-tips.png" alt="">
      </div>
      <div class="mb32">点击右上角 ··· ，分享给好友</div>
      <div class="mb60">后台可查看此链接点击数据</div>
      <div class="styleTemp flexBox flexCenter mb60">
        <div class="line leftLine">
          <div class="point"></div>
        </div>
        <span>样式预览</span>
        <div class="line rightLine">
          <div class="point"></div>
        </div>
      </div>
      <div class="grayBox flexBox">
        <img src="@/assets/icons/avatar.png" />
        <div class="contentBox">
          <div class="mb5">{{info.card_title}}</div>
          <div class="flexBetween flexCenter">
            <div class="contentDesc">{{info.card_desc}}</div>
            <img :src="info.card_img" />
          </div>
        </div>
      </div>
    </div>
    <JumpWechat :wxUrl="wxUrl" v-else />
  </div>
</template>
<script>
import wx from "weixin-js-sdk"
import { getJsConfig, getMiniAppScheme } from '@/api/index'
import JumpWechat from '@/components/JumpWechat'
import { Message } from 'element-ui';
export default {
  components: { JumpWechat },
  props:{
    isMB:{
      type:Boolean,
      default: false
    },
    info: {
      type: Object,
      default: () => {},
    },
  },
   data() {
      return {
        isWX: false,
        wxUrl:null,
      }
   },
   created() {
    this.userAgent()
  },
  methods: {
    userAgent(){
      // 获取 User Agent
      var userAgent = navigator.userAgent.toLowerCase();
      // 判断是否在微信中打开
      if (userAgent.indexOf('micromessenger') !== -1) {
          this.isWX = true
  	      this.getShareInfo()
      } else {
          this.isWX = false
          getMiniAppScheme({chain_code: this.info.chain_code})
          .then(res=>{
            this.wxUrl = res.data.scheme
            window.location.href= res.data.scheme
          })
      }
    },
    getShareInfo() {
      var that = this;
      var winUrl = window.location.href.split("#")[0]
      winUrl = encodeURIComponent(winUrl)
      getJsConfig({url:winUrl})
      .then((msg) => {
          wx.config({
            debug: false,
            appId: msg.data.appId, // 必填，公众号的唯一标识
            timestamp: msg.data.timestamp, // 必填，生成签名的时间戳
            nonceStr: msg.data.nonceStr, // 必填，生成签名的随机串
            signature: msg.data.signature, // 必填，签名，见附录1
            jsApiList: ["updateTimelineShareData", "updateAppMessageShareData"], // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
          });
          wx.ready(function () {
            //分享到朋友圈  需在用户可能点击分享按钮前就先调用
            wx.updateTimelineShareData({
              title: that.info.card_title||'', // 分享时的标题
              desc: that.info.card_desc||'', // 分享描述
              link: that.info.link_url||'', // 分享时的链接
              imgUrl: that.info.card_img||'', // 分享图标
              success: function () {
                // 设置成功
                // Message.success("分享到朋友圈成功")
              },
              cancel: function () {
                // 取消设置
                // Message("分享到朋友圈取消")
              },
              fail: function () {
                // Message.error("分享朋友圈失败")
              },
            });
            // 分享给朋友的设置
            wx.updateAppMessageShareData({
              title: that.info.card_title||'', // 分享时的标题
              desc: that.info.card_desc||'', // 分享描述
              link: that.info.link_url||'', // 分享时的链接
              imgUrl: that.info.card_img||'', // 分享图标
              success: function () {
                // 设置成功
                // Message.success("分享给朋友成功")
              },
              cancel: function () {
                // 设置失败
                // Message("分享给朋友取消")
              },
              fail: function () {
                // Message.error("分享给朋友失败")
              },
            });
          });
        }
      );
    }
  }
}
</script>
<style lang='less' scoped>
.page{
  background: #fff;
  overflow: hidden;
  line-height: 2.5;
  color: #515a6e;
}
.noBgPage{
  background: rgba(0,0,0,0) ;
}
.sharePage{
  width: 320px;
  height: 100vh;
  margin: 0 auto;
  text-align: center;
  overflow: hidden;
  .shareArrow{
    text-align:right;
    margin-top: 8px;
    img{
      width: 150px;
    }
  }
  .styleTemp{
    justify-content: space-between;
    font-size: 16px;
    white-space: nowrap;
    span{
      margin: 0 10px;
    }
    .line{
      width: 100%;
      height: 1px;
      background:#ebecf3;
      position: relative;
    }
    .point{
      top: 50%;
      transform: translate(0 ,-50%);
      position: absolute;
      width: 5px;
      height: 5px;
      background:#ebecf3;
      border-radius: 50%;
    }
    .leftLine{
      .point{
        right: 0;
      } 
    }
    .rightLine{
      .point{
        left: 0;
      } 
    }
  }
  .grayBox{
    width: 275px;
    background: #f7f7f7;
    border-radius: 4px;
    padding: 24px 11px;
    margin: 0 auto;
    img{
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-right: 8px;
    }
    .contentBox{
      text-align: left;
      width: 100%;
      min-height: 32px;
      background: #fff;
      border-radius: 4px;
      padding: 4px 8px 8px;
      font-size: 14px;
      color: #515a6e;
      .contentDesc{
        font-size: 12px;
        color: #787fa9;
        overflow: hidden;
        white-space: pre-line;
        word-break: break-all;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        margin-right: 8px;
      }
      img{
        width: 44px;
        height: 44px;
      }
    }
  }
  .mb32{
    margin-bottom: 32px;
    font-size: 16px;
  }
  .mb60{
    margin-bottom: 32px;
    font-size: 16px;
  }
  .mb{
    margin-bottom: 5px;
  }
}
</style>

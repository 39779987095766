<template>
  <div class="codePage" :class="isMB ? 'codePage2' : ''">
    <div class="codeBox">
      <div class="acTitle" v-if="info.is_advanced == 1 && info.activity_name">
        {{ info.activity_name }}
      </div>
      <div
        class="acDesc grayTxt"
        v-if="info.is_advanced == 1 && info.activity_desc"
      >
        {{ info.activity_desc }}
      </div>
      <div :class="isMB ? 'mt20' : ''">
        <div
          class="scanTips grayTxt"
          v-if="isMB && info.is_advanced == 1 && info.scan_tips == 1"
        >
          长按可保存下载
        </div>
        <div class="posterEgBox" v-if="info.image_list.length">
          <img
            v-for="item in info.image_list"
            :key="item.id"
            :src="item.url"
            alt=""
          />
        </div>
      </div>
    </div>
    <div
      class="contactBox flexBox flexCenter c-pointer"
      v-if="info.is_advanced == 1 && info.btn_type != 0"
      @click.stop="showHoverShadow = true"
    >
      <template>
        <img src="~@/assets/icons/icon_wechat_3.png" alt="" />
        <span class="grayTxt ml-10">联系活动方</span>
      </template>
    </div>
    <el-dialog
      :visible.sync="showHoverShadow"
      :show-close="false"
      :width="isMB ? '80%' : '300px'"
    >
      <div class="serviceTitle">
        <span v-if="info.btn_type == 1"
          >{{ isMB ? '长按识别' : '扫' }}二维码咨询活动方</span
        >
        <span v-if="info.btn_type == 2">咨询活动方</span>
      </div>
      <img
        v-if="info.btn_type == 1 && info.wechat_qrcode"
        class="contactPic"
        :class="isMB ? '' : 'contactPic2'"
        :src="info.wechat_qrcode"
      />
      <div class="contactText" v-if="info.btn_type == 2 && info.contact_number">
        {{ info.contact_number }}
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: {
    isMB: {
      type: Boolean,
      default: false,
    },
    info: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      showHoverShadow: false,
    }
  },
  created() {
    console.log('image_list',this.info);
  },
  computed: {},
  methods: {},
}
</script>
<style lang="less" scoped>
.codePage {
  width: 500px;
  margin: 0 auto;
  min-height: 100vh;
  text-align: center;
  overflow: hidden;
  position: relative;
  font-size: 16px;
}
.codePage2 {
  width: 100%;
}
.codeBox {
  .acTitle {
    font-size: 18px;
    font-weight: bold;
    margin-top: 60px;
  }
  .acDesc {
    font-size: 14px;
    width: 100%;
    padding: 15px 0 20px;
    word-break: break-all;
  }
}
.posterEgBox {
  width: 85%;
  margin: 20px auto 0;
  padding-bottom: 60px;
  img {
    width: 100%;
  }
}
.grayTxt {
  color: rgba(0, 0, 0, 0.65);
}
.contactBox {
  justify-content: center;
  position: absolute;
  z-index: 0;
  bottom: 25px;
  width: 100%;
  left: 0;
}
.ml-10 {
  margin-left: 10px;
  font-size: 16px;
}
/deep/.el-dialog {
  border-radius: 20px;
}
.serviceTitle {
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.85);
  margin: 0 0 30px;
}
.contactPic {
  width: 80%;
}
.contactPic2 {
  width: 150px;
}
.contactText {
  text-align: left;
}
.mt20 {
  margin-top: 20px;
}
</style>

import { render, staticRenderFns } from "./JumpWechat.vue?vue&type=template&id=599f05bc&scoped=true"
import script from "./JumpWechat.vue?vue&type=script&lang=js"
export * from "./JumpWechat.vue?vue&type=script&lang=js"
import style0 from "./JumpWechat.vue?vue&type=style&index=0&id=599f05bc&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "599f05bc",
  null
  
)

export default component.exports
import axios from 'axios'
import { Message } from 'element-ui'
// import auth from "@/utils/auth.js";
import Qs from 'qs' // 引入qs  是axios的自带模块

const service = axios.create({
  baseURL: '/lp', // url = base url + request url
  timeout: 5000,
  headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
  transformRequest: [
    function (data, headers) {
      if (headers['Content-Type'] !== 'multipart/form-data') {
        // 在请求之前对data传参进行格式转换
        data = Qs.stringify(data)
      }
      return data
    }
  ],
  withCredentials: true // send cookies when cross-domain requests
})

const errorHandler = error => {
  console.log('error',error);
  if (error.response) {
    console.log(error.response,"error.response");
    Message({
      message: error.message,
      type: 'error',
      duration: 5000,
    })
    return Promise.reject(error)
  }
  return Promise.reject(error)
}
// Request interceptors
service.interceptors.request.use(config => {
  return config
}, errorHandler)

// Response interceptors
service.interceptors.response.use(
  (response) => {
    const res = response.data
    if (res.success) {
      return Promise.resolve(res)
    } else {
      Message({
        message: res.message || 'Error',
        type: 'error',
        duration: 2000,
      })
      return Promise.reject(res)
    }
  },errorHandler)

export default service

import request from './request'
/**
 * 获取信息
 * @returns
 */
export function initInfo(params = {}) {
  return request({
    url: '/info',
    method: 'get',
    needReferrer: true,
    params
  })
}
// 获取微信卡片配置
export function getJsConfig(params = {}) {
  return request({
    url: '/getJsConfig',
    method: 'get',
    params
  })
}
// 获取小程序连接
export function getMiniAppScheme(params = {}) {
  return request({
    url: '/getMiniAppScheme',
    method: 'get',
    params
  })
}
// 获取分组二维码
export function getGroupQrcode(params = {}) {
  return request({
    url: '/getGroupQrcode',
    method: 'get',
    params
  })
}
<template>
  <div class="codePage" :class="isMB ? 'codePage2' : ''">
    <div class="ldPageName">{{info.activity_name}}</div>
    <div class="codePic">
      <img :src="info.activity_img" alt="">
    </div>
    <div class="desc grayTxt" v-if="info.is_advanced==1&&info.activity_desc">{{info.is_advanced==1?info.activity_desc:''}}</div>
    <div class="ldPageKind" :class="info.is_advanced==1 && info.btn_type != 0 ? 'pb-60' : ''">
      <div class="ldPageKindTitle" v-if="info.group_tips_switch==1">
        {{ info.group_tips || '👇👇请在下方选择类目👇👇' }}
      </div>
      <div class="flexBox flexBetween flexWrap">
        <div class="ldPageKindItem" v-for="(item, index) in info.group_list" :key="index" @click="jumpCodePage(item)">
          {{ item.name || '未命名分组' }}
        </div>
      </div>
    </div>
    <div
      class="contactBox flexBox flexCenter c-pointer"
      v-if="info.is_advanced==1 && info.btn_type != 0"
      @click.stop="showHoverShadow = true"
    >
      <template>
        <img src="~@/assets/icons/icon_wechat_3.png" alt="" />
        <span class="grayTxt ml-10">联系活动方</span>
      </template>
    </div>
    <el-dialog :visible.sync="showHoverShadow" :show-close="false" :width="isMB?'80%':'300px'">
      <div class="serviceTitle">
        <span v-if="info.btn_type == 1">{{isMB?'长按识别':'扫'}}二维码咨询活动方</span>
        <span v-if="info.btn_type == 2">咨询活动方</span>
      </div>
      <img v-if="info.btn_type == 1 && info.wechat_qrcode" class="contactPic" :class="isMB?'':'contactPic2'" :src="info.wechat_qrcode" />
      <div class="contactText" v-if="info.btn_type == 2 && info.contact_number">{{ info.contact_number }}</div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: {
    isMB: {
      type: Boolean,
      default: false,
    },
    info: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      showHoverShadow: false
    }
  },
  created() {},
  computed: {},
  methods: {
    jumpCodePage(item){
      this.$router.push({
        name:'DivideScanCode',
        query:{
          group_id:item.id,
          chain_code:this.info.chain_code,
          channel_id:this.$route.query.channel_id
        }
      })
    }
  },
}
</script>
<style lang="less" scoped>
.codePage {
  width: 500px;
  margin: 0 auto;
  min-height: 100vh;
  text-align: center;
  overflow: hidden;
  position: relative;
}
.codePage2 {
  width: 100%;
}
.grayTxt{
  color:rgba(0,0,0,.65);
}
.contactBox {
  justify-content: center;
  position: absolute;
  z-index: 0;
  bottom: 25px;
  width: 100%;
  left: 0;
}
.ml-10{
  margin-left: 10px;
  font-size: 16px;
}
.ldPageName{
  margin-top: 20px;
  font-size: 18px;
  line-height: 20px;
  font-weight: 700;
  color: #000;
}
.codePic{
  margin: 20px 0px 15px;
  img{
    width: 100%;
  }
}
.desc{
  font-size: 14px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  padding: 0 0 8px;
}
.ldPageKind {
  font-size: 16px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.85);
  padding: 20px 20px 0;
  .ldPageKindTitle{
    margin-bottom: 20px;
  }
  .ldPageKindItem {
    border-radius: 24px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    color: rgba(0, 0, 0, 0.45);
    font-size: 12px;
    font-weight: normal;
    margin-bottom: 15px;
    width: 40%;
    padding: 0 4px;
    line-height: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
/deep/.el-dialog{
  border-radius: 20px;
}
.serviceTitle{
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.85);
  margin: 0 0 30px;
}
.contactPic{
  width: 80%;
}
.contactPic2{
  width: 150px;
}
.contactText{
  text-align: left;
}
.flexWrap {
  flex-wrap: wrap;
}
.pb-60 {
  padding-bottom: 60px;
}
</style>

<template>
  <div class="codePage" :class="isMB?'codePage2':''">
    <div class="saveTips flexBox flexCenter" v-if="needSave">
      <img src="~@/assets/icons/icon_certification_green.png" class="saveLogo" />
      <span>二维码已通过安全认证，请放心扫描。</span>
    </div>
    <div class="title">{{info.is_advanced==1?info.activity_name:''}}</div>
    <div class="codePic">
      <img :src="info.qr_code" alt="">
    </div>
    <div v-if="info.scan_tips==1" class="scanTips grayTxt" :class="isMB?'':'hiddenBox'">长按识别上方二维码</div>
    <div class="desc grayTxt">{{info.is_advanced==1?info.activity_desc:''}}</div>
    <div class="support grayTxt" v-if="info.chain_type == 13&&info.is_technical_support!=1">由「W外链」提供技术支持</div>
    <div
      class="contactBox flexBox flexCenter c-pointer"
      v-if="info.is_advanced==1 && info.btn_type != 0"
      @click.stop="showHoverShadow = true"
    >
      <template>
        <img src="~@/assets/icons/icon_wechat_3.png" alt="" />
        <span class="grayTxt ml-10">联系活动方</span>
      </template>
    </div>
    <el-dialog :visible.sync="showHoverShadow" :show-close="false" :width="isMB?'80%':'300px'">
      <div class="serviceTitle">
        <span v-if="info.btn_type == 1">{{isMB?'长按识别':'扫'}}二维码咨询活动方</span>
        <span v-if="info.btn_type == 2">咨询活动方</span>
      </div>
      <img v-if="info.btn_type == 1 && info.wechat_qrcode" class="contactPic" :class="isMB?'':'contactPic2'" :src="info.wechat_qrcode" />
      <div class="contactText" v-if="info.btn_type == 2 && info.contact_number">{{ info.contact_number }}</div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: {
    isMB:{
      type:Boolean,
      default: false
    },
    info: {
      type: Object,
      default: () => {},
    },
  },
   data() {
      return {
        showHoverShadow: false
      }
   },
   created(){
   },
   computed:{
    needSave() {
      if (this.info.chain_type == 13) {
        if (this.info.is_security==1) {
          return true
        } else {
          return false
        }
      } else {
        if (this.info.is_advanced==1&&this.info.is_security==1) {
          return true
        } else {
          return false
        }
      }
    }
   },
   methods:{
   },
}
</script>
<style lang="less" scoped>
.codePage{
  width: 500px;
  margin: 0 auto;
  min-height: 100vh;
  text-align: center;
  overflow: hidden;
  position: relative;
}
.codePage2{
  width: 100%;
}
.saveTips {
  background: #d3fae5;
  color: #25b981;
  position: absolute;
  z-index: 0;
  top: 0px;
  left: 0px;
  width: 100%;
  box-sizing: border-box;
  padding: 8px 0;
  font-size: 14px;
  justify-content: center;
}
.title{
  margin-top: 80px;
  font-size: 18px;
  line-height: 20px;
  font-weight: 700;
  color: #000;
}
.codePic{
  width: 60%;
  margin: 40px auto;
  img{
    width: 100%;
  }
}
.grayTxt{
  color:rgba(0,0,0,.65);
}
.scanTips{
  font-size: 16px;
  margin-bottom: 40px;
}
.desc{
  font-size: 14px;
}
.contactBox {
  justify-content: center;
  position: absolute;
  z-index: 0;
  bottom: 25px;
  width: 100%;
  left: 0;
}
.support{
  font-size: 18px;
  margin-top: 60px;
}
.ml-10{
  margin-left: 10px;
  font-size: 16px;
}
/deep/.el-dialog{
  border-radius: 20px;
}
.serviceTitle{
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.85);
  margin: 0 0 30px;
}
.contactPic{
  width: 80%;
}
.contactPic2{
  width: 150px;
}
.contactText{
  text-align: left;
}
</style>

<template>
  <div>
    <div class="container flexBox flexCenter" v-if="!isMB">
      <div class="waterBgBox">
        <div class="footerWater">
          <svg
            class="waves"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            viewBox="0 24 150 28"
            preserveAspectRatio="none"
            shape-rendering="auto"
          >
            <defs>
              <path
                id="gentle-wave"
                d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
              />
            </defs>
            <g class="parallax">
              <use
                xlink:href="#gentle-wave"
                x="48"
                y="0"
                fill="rgba(255,255,255,0.7"
              />
              <use
                xlink:href="#gentle-wave"
                x="48"
                y="3"
                fill="rgba(255,255,255,0.5)"
              />
              <use
                xlink:href="#gentle-wave"
                x="48"
                y="5"
                fill="rgba(255,255,255,0.3)"
              />
              <use xlink:href="#gentle-wave" x="48" y="7" fill="#fff" />
            </g>
          </svg>
        </div>
      </div>
      <div class="contentBox">
        <div class="contentInner flexBox flexCenter">
          <img :src="info.qrcode" alt="" />
        </div>
        <div class="mt-30">
          请使用手机微信扫码，打开“{{ info.nickname }}”小程序
        </div>
      </div>
    </div>
    <JumpWechat :wxUrl="info.scheme" v-else />
  </div>
</template>
<script>
import JumpWechat from '@/components/JumpWechat'
export default {
  components: { JumpWechat },
  props: {
    isMB: {
      type: Boolean,
      default: false,
    },
    info: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {}
  },
  created() {
    if (this.isMB) {
      window.location.href= this.info.scheme
    }
  },
  computed: {},
  methods: {
  },
}
</script>
<style lang="less" scoped>
.container {
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  background: #f5f5f5;
}
.contentBox {
  text-align: center;
  z-index: 9;
  font-size: 16px;
  color: #fff;
  .contentInner {
    justify-content: center;
    width: 295px;
    height: 295px;
    padding: 10px;
    border-radius: 16px;
    background-color: #fff;
    img {
      width: 80%;
    }
  }
}
.mt-30 {
  margin-top: 20px;
}
.waterBgBox {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(
    60deg,
    rgba(84, 58, 183, 1) 0%,
    rgba(0, 172, 193, 1) 100%
  );
}
.footerWater {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
}
.parallax > use {
  animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
}

.parallax > use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 7s;
}

.parallax > use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 10s;
}

.parallax > use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 13s;
}

.parallax > use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 20s;
}

@keyframes move-forever {
  0% {
    transform: translate3d(-90px, 0, 0);
  }

  100% {
    transform: translate3d(85px, 0, 0);
  }
}
</style>

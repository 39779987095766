<template>
  <div class="dyPage">
    <div class="innerBox">
      <div style="position: relative">
        <img class="jumpImg" src="@/assets/icons/convert.png" />
        <div class="dotBox">
          <div class="dot-loader"></div>
          <div class="dot-loader"></div>
          <div class="dot-loader"></div>
          <div class="dot-loader"></div>
        </div>
      </div>
      <div class="jumpBtn">
        <i class="el-icon-loading"></i>
        <span @click="jump">点击立即跳转</span>
      </div>
    </div>
  </div>
</template>
<script>
import { getJsConfig, getMiniAppScheme } from '@/api/index'
export default {
  props: {
    isMB: {
      type: Boolean,
      default: false,
    },
    info: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {}
  },
  created() {
    this.getShareInfo()
  },
  computed: {
  },
  methods: {
    jump() {
      getMiniAppScheme({ chain_code: this.info.chain_code }).then((res) => {
        this.wxUrl = res.data.scheme
        window.location.href = res.data.scheme
      })
    },
    getShareInfo() {
      var that = this
      var winUrl = window.location.href.split("#")[0]
      winUrl = encodeURIComponent(winUrl)
      getJsConfig({ url: winUrl }).then((msg) => {
        wx.config({
          debug: false,
          appId: msg.data.appId, // 必填，公众号的唯一标识
          timestamp: msg.data.timestamp, // 必填，生成签名的时间戳
          nonceStr: msg.data.nonceStr, // 必填，生成签名的随机串
          signature: msg.data.signature, // 必填，签名，见附录1
          jsApiList: ['updateTimelineShareData', 'updateAppMessageShareData'], // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
        })
        wx.ready(function () {
          //分享到朋友圈  需在用户可能点击分享按钮前就先调用
          wx.updateTimelineShareData({
            title: that.info.card_title, // 分享时的标题
            desc: that.info.card_desc, // 分享描述
            link: that.info.link_url, // 分享时的链接
            imgUrl: that.info.card_img, // 分享图标
            success: function () {
              // 设置成功
            },
            cancel: function () {
              // 取消设置
            },
            fail: function () {
            },
          })
          // 分享给朋友的设置
          wx.updateAppMessageShareData({
            title: that.info.card_title, // 分享时的标题
            desc: that.info.card_desc, // 分享描述
            link: that.info.link_url, // 分享时的链接
            imgUrl: that.info.card_img, // 分享图标
            success: function () {
              // 设置成功
            },
            cancel: function () {
              // 设置失败
            },
            fail: function () {
            },
          })
        })
      })
      .finally(()=>{
        this.jump()
      })
    },
  },
}
</script>
<style lang="less" scoped>
.dyPage {
  width: 100%;
  height: 100vh;
  max-width: 500px;
  margin: 0 auto;
  background: #fff;
  overflow: hidden;
  text-align: center;
  position: relative;
  .innerBox {
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    left: 0;
  }
  .jumpImg {
    width: 70%;
  }
  .dotBox {
    display: flex;
    position: absolute;
    top: 57%;
    left: 50%;
    transform: translate(-50%, 0);
  }
  .jumpBtn {
    width: 66%;
    margin: 110px auto 0;
    background-color: #1e90ff;
    line-height: 50px;
    font-weight: 300;
    font-size: 16px;
    color: #ffffff;
    border-radius: 3px;
    span {
      color: #fff;
      margin-left: 4px;
    }
  }
}
.dot-loader {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background-color: #8e97a1;
  position: relative;
  -webkit-animation: 1.2s scaleDown ease-in-out infinite;
  animation: 1.2s scaleDown ease-in-out infinite;
  margin-right: 8px;
}
.dot-loader:nth-child(2) {
  animation: 1.2s scaleDown ease-in-out infinite 0.15555s;
}
.dot-loader:nth-child(3) {
  animation: 1.2s scaleDown ease-in-out infinite 0.3s;
}
.dot-loader:nth-child(4) {
  animation: 1.2s scaleDown ease-in-out infinite 0.46s;
  margin-right: 0px;
}
@keyframes scaleDown {
  0% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
  80% {
    transform: scale(0);
  }
  100% {
    transform: scale(0);
  }
}
</style>

import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/Index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/:code',
    name: 'Index',
    meta:{
      title:''
    },
    component: Index
  },
  {
    path: '/divideScanCode',
    name: 'DivideScanCode',
    meta:{
      title:'分组扫码页'
    },
    component: () => import('@/views/DivideScanCode'),
  },
  {
    path: '/404',
    name: '404',
    meta:{
      title:'链接不存在'
    },
    component: () => import('@/views/404'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

<template>
  <div class="jumpPage">
    <div class="saveTips flexCenter">
      <img src="@/assets/icons/safe.png" />
      <div>本链接经过<span>SSL安全加密</span>,请放心点击!</div>
    </div>
    <img src="@/assets/icons/wx_logo.png" />
    <div class="jumpLoading">正在跳转中...</div>
    <div>如未自动打开微信请点击下方按钮</div>
    <div class="jumpBtn" @click="openWechat">点击立即前往微信</div>
  </div>
</template>
<script>
export default {
  props:{
    immediateJump:{
      type: Boolean,
      default: false
    },
    wxUrl:{
      type:String,
      default: null
    }
  },
   data() {
      return {
      }
   },
   created(){
    if (this.immediateJump) {
      this.openWechat()
    }
   },
   computed:{
   },
   methods:{
    openWechat(){
      window.location.href= this.wxUrl
    }
   },
}
</script>
<style lang='less' scoped>
.jumpPage{
  margin-top: 140px;
  text-align: center;
  font-size: 16px;
  line-height: 2;
  color: #666666;
  .saveTips{
    justify-content: center;
    width: 100%;
    font-size: 12px;
    position: fixed;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    img{
      width: 16px;
      height: 16px;
      margin-right: 6px;
    }
    span{
      font-weight: 500;
      color: #000;
    }
  }
  img{
    width: 80px;
    height: 80px;
  }
  .jumpLoading{
    font-size: 12px;
  }
  .jumpBtn{
    width: 250px;
    background-color: #06ae56;
    color: #FFFFFF;
    margin: 50px auto;
    border-radius: 3px;
    line-height: 50px;
  }
}
</style>

<template>
  <div class="" :class="isWX?'':'noBgPage'">
    <div class="bgBox" v-if="!isMB||isWX" :style="{ backgroundImage: 'url(' + info.bg_img + ')' }">
      <div class="page" :class="isMB?'page2':''">
        <div class="flexBox flexCenter">
          <el-avatar :src="info.avatar" :size="60" style="min-width: 60px" />
          <div class="titleContent">
            <div class="title">{{ info.title }}</div>
            <div class="subTitle">{{ info.sub_title }}</div>
          </div>
        </div>
        <div class="qrcode">
          <img :src="info.qr_code" alt="" />
        </div>
        <div class="scanTips" :class="isMB?'':'hiddenBox'" v-if="info.scan_tips==1">长按识别上方二维码</div>
      </div>
    </div>
    <JumpWechat immediateJump :wxUrl="info.scheme" v-else />
  </div>
</template>
<script>
import JumpWechat from '@/components/JumpWechat'
export default {
  components: { JumpWechat },
  props: {
    isMB:{
      type:Boolean,
      default: false
    },
    info: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isWX: false,
    }
  },
  created() {
    console.log('info', this.info)
    this.userAgent()
  },
  computed: {},
  methods: {
    userAgent(){
      // 获取 User Agent
      var userAgent = navigator.userAgent.toLowerCase();
      // 判断是否在微信中打开
      if (userAgent.indexOf('micromessenger') !== -1) {
        this.isWX = true
      } else {
        this.isWX = false
      }
      console.log('微信中打开',this.isWX)
    }
  },
}
</script>
<style lang="less" scoped>
.bgBox{
  background:#f5f5f5 no-repeat top;
  background-size: 100% auto;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}
.page {
  box-sizing: border-box;
  background:#fff;
  padding: 18px;
  width: 500px;
  border-radius: 10px;
}
.page2{
  width: 90%;
  border-radius: 0;
}
.titleContent {
  margin-left: 10px;
}
.title{
  font-size: 16px;
}
.subTitle {
  font-size: 14px;
  color: #9ca3af;
}
.qrcode {
  margin: 20px 0;
  img {
    width: 100%;
  }
}
.scanTips{
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: #2F495E;
}
.noBgPage{
  background: rgba(0,0,0,0);
}
</style>
